import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core'
import { ApiDataSource } from '@ui/Table'
import { ComponentEventTriggered } from '@ui/Components/Table/CdkTableCell.Component'
import { SelectionModel } from '@angular/cdk/collections'
import { CdkDragDrop } from '@angular/cdk/drag-drop'

export type ButtonColors = 'primary' | 'warn'

export type Callback = () => void

type RowActionOutput<T> = (entity: T) => void | Promise<void>

type DynamicRowActionText<T> = (entity: T) => string

type DynamicRowActionHidden<T> = (entity: T) => boolean

export type DynamicRowActionButtonColor<T> = (entity: T) => ButtonColors

export interface RowAction<T> {
	title: string | DynamicRowActionText<T>
	color: ButtonColors | DynamicRowActionButtonColor<T>
	output: RowActionOutput<T>
	shouldHide?: DynamicRowActionHidden<T>
}

interface CdkComponentRow<T> {
	instance: any
	subscribedListeners?: string[]
	notClickable?: boolean
	additionalData?: { [key: string]: any | RowActionOutput<T> }
	additionalCallbacks?: { [key: string]: RowActionOutput<T> }
}

interface CdkExpansionRow<T> {
	renderHtml: (entity: T) => string
	elementClick?: (entity: T) => void | Promise<void>
}

export interface CdkTableHeaderCheckbox {
	toggled: Callback
	disabled: boolean
	selection: SelectionModel<any>
	totalItems: number
}

export interface CdkTableFooter<T> {
	label: string
	value: string
	valueCallback?: (entity: T) => string
}

export interface CdkCurrencyRow {
	currency: string
	locale: string
	currencyPrecision: number
}

export interface CdkTableRow<T> {
	header?: string | CdkTableHeaderCheckbox
	columnDef: string
	accessorKey?: keyof T
	accessorKeys?: Array<keyof T>
	type?:
		| 'date'
		| 'datetime'
		| 'time'
		| 'action'
		| 'component'
		| 'currency'
		| 'expansion'
	timezone?: string
	currency?: CdkCurrencyRow
	action?: RowAction<T>
	component?: CdkComponentRow<T>
	expansion?: CdkExpansionRow<T>
	ignoreRowActionCallback?: boolean
	accessorCallback?(entity: T, index: number): string | number
	getRowColor?(entity: T): string
	getExpandedHtml?(entity: T): string
}

export interface ActionCalledEvent {
	entity: any
	value: any
	columnDef: string
	index: number
	subscribedEventName: string
}

export interface DraggableCells<T> {
	dropItem(event: CdkDragDrop<T>): Promise<void> | void
}

@Component({
	selector: 'cdk-table-component',
	templateUrl: './CdkTable.Component.html',
	styleUrls: ['./CdkTable.Component.css'],
})
export class CdkTableComponent implements OnChanges {
	@Input() public displayColumns: string[]

	@Input() public entities: Array<CdkTableRow<any>>

	@Input() public dataSource: ApiDataSource<any>

	@Input() public footer: CdkTableFooter<any>

	@Input() public draggable: DraggableCells<any>

	@Output() private readonly rowClickAction = new EventEmitter<any>()

	@Output() private readonly actionCalled =
		new EventEmitter<ActionCalledEvent>()

	public cursor = 'default'

	public expandedEntity: any

	public expansionColumnNames: string[] = []

	public headerDiff: number = -1

	public ngOnChanges(): void {
		const entityLength = this.entities.filter(
			(row: CdkTableRow<any>) => row.type !== 'expansion',
		).length

		this.headerDiff = this.displayColumns.length - entityLength + 1

		if (this.rowClickAction.observers.length > 0) {
			this.cursor = 'pointer'
		}

		const expansionColumnNames: string[] = []

		this.entities.forEach((entity: CdkTableRow<any>) => {
			if (entity.type === 'expansion') {
				expansionColumnNames.push(entity.columnDef)
			}
		})

		this.expansionColumnNames = expansionColumnNames
	}

	public trackBy = (index: number): number => index

	public handleRowClick = (
		entityAccessor: CdkTableRow<any>,
		entity: any,
	): void => {
		if (this.expansionColumnNames.length > 0) {
			return
		}

		if (entityAccessor.ignoreRowActionCallback) {
			return
		}

		if (
			typeof entityAccessor.component === 'undefined' ||
			!entityAccessor.component.notClickable
		) {
			this.rowClickAction.emit(entity)
		}
	}

	public handleComponentEvent = (
		event: ComponentEventTriggered<any>,
		index: number,
	) => {
		this.actionCalled.emit({ ...event, index })
	}

	public toggleRowExpansion = (entity) => {
		this.expandedEntity = this.expandedEntity === entity ? undefined : entity
	}
}
