import {
	Component,
	ComponentFactoryResolver,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
	Output,
	EventEmitter,
	ComponentRef,
} from '@angular/core'
import {
	CdkTableRow,
	ButtonColors,
	RowAction,
} from '@ui/Components/Table/CdkTable.Component'
import { AdDirective } from '@ui/Components/Table/Ad.Directive'

export interface ComponentEventTriggered<T> {
	subscribedEventName: string
	entity: T
	value: any
	columnDef: string
}

@Component({
	selector: 'cdk-table-cell',
	templateUrl: './CdkTableCell.Component.html',
})
export class CdkTableCellComponent implements OnChanges {
	@Input() public entityAccessor: CdkTableRow<any>

	@Input() public entity: any

	@Input() public index: number

	@ViewChild(AdDirective, { static: true }) public adHost: AdDirective

	@Output() private readonly componentEventTriggered = new EventEmitter<
		ComponentEventTriggered<any>
	>()

	public currentValue: any

	public color = '#000000'

	public actionTitle: string

	public actionColor: ButtonColors

	public hidden: boolean

	public componentRef: ComponentRef<any>

	public expanded = false

	constructor(private readonly resolver: ComponentFactoryResolver) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.entityAccessor.getRowColor) {
			this.color = this.entityAccessor.getRowColor(this.entity)
		}

		if (this.entityAccessor.accessorKey) {
			this.currentValue = this.entity[this.entityAccessor.accessorKey]
		}

		if (this.entityAccessor.accessorCallback) {
			this.currentValue = this.entityAccessor.accessorCallback(
				this.entity,
				this.index,
			)
		}

		if (this.entityAccessor.action) {
			this.actionTitle =
				typeof this.entityAccessor.action.title === 'function'
					? this.entityAccessor.action.title(this.entity)
					: this.entityAccessor.action.title

			this.actionColor =
				typeof this.entityAccessor.action.color === 'function'
					? this.entityAccessor.action.color(this.entity)
					: this.entityAccessor.action.color

			this.hidden =
				typeof this.entityAccessor.action.shouldHide === 'undefined'
					? false
					: this.entityAccessor.action.shouldHide(this.entity)
		}

		if (this.entityAccessor.accessorKeys) {
			const values = this.entityAccessor.accessorKeys.map((key: string) => {
				return this.entity[key]
			})

			this.currentValue = values.join(' ')
		}

		if (
			changes.entityAccessor &&
			changes.entityAccessor.currentValue.component
		) {
			if (!this.componentRef) {
				const factory = this.resolver.resolveComponentFactory(
					changes.entityAccessor.currentValue.component.instance,
				)

				const viewContainerRef = this.adHost.viewContainerRef

				viewContainerRef.clear()

				this.componentRef = viewContainerRef.createComponent(factory)
			}

			if (changes.entityAccessor.currentValue.component.additionalData) {
				Object.keys(
					changes.entityAccessor.currentValue.component.additionalData,
				).forEach((key: string) => {
					if (
						typeof changes.entityAccessor.currentValue.component.additionalData[
							key
						] === 'function'
					) {
						this.componentRef.instance[key] =
							changes.entityAccessor.currentValue.component.additionalData[key](
								this.entity,
							)
					} else {
						this.componentRef.instance[key] =
							changes.entityAccessor.currentValue.component.additionalData[key]
					}
				})
			}

			if (changes.entityAccessor.currentValue.component.additionalCallbacks) {
				Object.keys(
					changes.entityAccessor.currentValue.component.additionalCallbacks,
				).forEach((key: string) => {
					this.componentRef.instance[key] =
						changes.entityAccessor.currentValue.component.additionalCallbacks[
							key
						]
				})
			}

			if (changes.entityAccessor.currentValue.component.subscribedListeners) {
				changes.entityAccessor.currentValue.component.subscribedListeners.forEach(
					(key: string) => {
						this.componentRef.instance[key].subscribe((value: any) => {
							this.componentEventTriggered.emit({
								subscribedEventName: key,
								value,
								entity: this.entity,
								columnDef: this.entityAccessor.columnDef,
							})
						})
					},
				)
			}
		}
	}

	public handleActionClick = (
		event: MouseEvent,
		action: RowAction<any>,
		entity: any,
	) => {
		event.stopPropagation()

		action.output(entity)
	}
}
